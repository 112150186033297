var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Warning from "@mui/icons-material/Warning";
import { IconButton, FormControl, FormHelperText, Grid, TextField, Autocomplete, } from "@mui/material";
import { Controller } from "react-hook-form";
export var FormReviewSelect = function (props) {
    var _a, _b, _c;
    var attr = props.attr, label = props.label, options = props.options, control = props.control, setValue = props.setValue, watch = props.watch, _d = props.withReview, withReview = _d === void 0 ? true : _d;
    var defaultError = {
        status: Boolean((_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })),
        attr: (_c = (_b = watch("errors")) === null || _b === void 0 ? void 0 : _b.find(function (e) { return e.field === attr; })) === null || _c === void 0 ? void 0 : _c.attr,
    };
    var _e = useState(defaultError), error = _e[0], setError = _e[1];
    var getErrorMsg = function () {
        var _a, _b;
        return ((_b = (_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })) === null || _b === void 0 ? void 0 : _b.text) || "";
    };
    var getErrorAttr = function () {
        var _a, _b;
        return ((_b = (_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })) === null || _b === void 0 ? void 0 : _b.attr) || "";
    };
    var handleError = function () {
        if (error.status) {
            setValue("errors", __spreadArray([], watch("errors").filter(function (item) { return item.field !== attr; }), true));
        }
        else {
            setValue("errors", __spreadArray(__spreadArray([], watch("errors"), true), [
                { field: attr, attr: "admin", text: "please review this " + attr },
            ], false));
        }
        setError({ attr: "admin", status: !error.status });
    };
    var getErrorClassName = function () {
        if (getErrorMsg() !== "") {
            if (getErrorAttr() === "admin") {
                return "admin request review";
            }
            else {
                return "request review";
            }
        }
        return "review";
    };
    // const [val, setVal] = useState(value);
    // const handleChange = (
    //   event: SyntheticEvent<Element, Event>,
    //   newValue: Option | null
    // ) => {
    //   setVal(newValue?.value || "");
    //   setValue(attr, newValue?.value || "");
    //   setSelectedValue(newValue);
    //
    //   //props.onSelectedChange(attr, event.target.value);
    // };
    return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ className: getErrorClassName(), sx: { width: "100%" } }, { children: [_jsx(Controller, { name: attr, control: control, render: function (_a) {
                        var field = _a.field;
                        return (_jsx(Autocomplete, __assign({}, field, { className: withReview ? "withreview" : "", value: field.value && Object.keys(field.value).length > 0
                                ? field.value
                                : null, isOptionEqualToValue: function (option, value) { var _a, _b; return ((_a = option === null || option === void 0 ? void 0 : option.value) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = value === null || value === void 0 ? void 0 : value.value) === null || _b === void 0 ? void 0 : _b.toString()); }, options: options, getOptionLabel: function (option) { return "".concat(option.label); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: label || "" }))); }, onChange: function (_, data) { return field.onChange(data); } })));
                    } }), withReview ? (_jsx(IconButton, __assign({ sx: { position: "absolute", top: 8, right: 1 }, onClick: handleError }, { children: _jsx(Warning, {}) }))) : (""), _jsx(FormHelperText, { children: getErrorMsg() })] })) })));
};
