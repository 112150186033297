var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, Button, Menu, MenuItem, ListItemText, ListItemIcon, } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu, } from "material-ui-popup-state/hooks";
import { useQueryClient } from "react-query";
import { useAuth, useUrlQueryParams } from "@modules/core/hooks";
import { searchParamsAPI } from "@modules/core/config";
export var PageNavbar = function () {
    var _a = useUrlQueryParams(searchParamsAPI), searchParams = _a[0], setSearchParams = _a[1];
    var _b = useAuth(), user = _b.user, logout = _b.logout;
    var navigate = useNavigate();
    var queryClient = useQueryClient();
    var popupState = usePopupState({ variant: "popover", popupId: "setting" });
    var selectItem = function (menu) {
        navigate("/" + menu);
        popupState.close();
    };
    var downloadItem = function (file) {
        var link = document.createElement("a");
        link.download = file;
        link.href = "./client/download/public/" + file;
        link.click();
    };
    var styleLogoLink = {
        lineHeight: "14px",
    };
    return (_jsx("nav", __assign({ className: "product-navbar" }, { children: _jsxs("div", __assign({ className: "container" }, { children: [_jsx(Link, __assign({ style: styleLogoLink, to: "/products" }, { children: _jsx("img", { className: "navbar-logo", src: "/client/img/gfk_logo_48.png" }) })), _jsxs("div", __assign({ className: "product-search" }, { children: [_jsx("input", { name: "search", value: searchParams === null || searchParams === void 0 ? void 0 : searchParams.title, onChange: function (e) { return setSearchParams({ title: e.target.value }); }, placeholder: "find" }), _jsx(IconButton, __assign({ style: {
                                border: "1px solid white",
                                borderRadius: 0,
                                padding: 0,
                                width: 34,
                                height: 34,
                            }, onClick: function () {
                                navigate("/products?title=" + searchParams.title);
                            } }, { children: _jsx(SearchIcon, { sx: { color: "#fff" } }) }))] })), user ? (_jsxs("div", __assign({ className: "menu-account" }, { children: [_jsx(Button, __assign({ color: "inherit", variant: "text", startIcon: _jsx(PersonIcon, {}), disableElevation: true }, bindTrigger(popupState), { children: user.username })), _jsxs(Menu, __assign({}, bindMenu(popupState), { anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" } }, { children: [_jsxs(MenuItem, __assign({ onClick: function () { return selectItem("products"); } }, { children: [_jsx(ListItemIcon, { children: _jsx(DashboardIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "DASHBOARD" })] })), _jsxs(MenuItem, __assign({ onClick: function () { return selectItem("profile"); } }, { children: [_jsx(ListItemIcon, { children: _jsx(SettingsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "PROFILE" })] })), _jsx(Divider, {}), _jsxs(MenuItem, __assign({ onClick: function () {
                                        return downloadItem("GFK_Artikelstamm_Anmeldung_physisch_GSA.xlsx");
                                    } }, { children: [_jsx(ListItemIcon, { children: _jsx(DownloadIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "EXCEL TEMPLATE" })] })), _jsx(Divider, {}), _jsxs(MenuItem, __assign({ onClick: function () { return logout(); } }, { children: [_jsx(ListItemIcon, { children: _jsx(ExitToAppIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "LOGOUT" })] }))] }))] }))) : ("")] })) })));
};
