var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { IconButton, OutlinedInput, InputAdornment, FormControl, FormHelperText, InputLabel, Grid, } from "@mui/material";
import Warning from "@mui/icons-material/Warning";
export var FormReviewInput = function (props) {
    var _a, _b, _c;
    var isText = props.isText, attr = props.attr, label = props.label, watch = props.watch, setValue = props.setValue, getValues = props.getValues;
    var defaultError = {
        status: Boolean((_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })),
        attr: (_c = (_b = watch("errors")) === null || _b === void 0 ? void 0 : _b.find(function (e) { return e.field === attr; })) === null || _c === void 0 ? void 0 : _c.attr,
    };
    var _d = useState(defaultError), error = _d[0], setError = _d[1];
    var getErrorMsg = function () {
        var _a, _b;
        return ((_b = (_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })) === null || _b === void 0 ? void 0 : _b.text) || "";
    };
    var getErrorAttr = function () {
        var _a, _b;
        return ((_b = (_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.field === attr; })) === null || _b === void 0 ? void 0 : _b.attr) || "";
    };
    var handleChange = function (event) {
        setValue(attr, event.target.value);
    };
    var handleError = function () {
        if (error.status) {
            setValue("errors", __spreadArray([], watch("errors").filter(function (item) { return item.field !== attr; }), true));
        }
        else {
            setValue("errors", __spreadArray(__spreadArray([], watch("errors"), true), [
                { field: attr, attr: "admin", text: "please review this " + attr },
            ], false));
        }
        setError({ attr: "admin", status: !error.status });
        console.log(watch("errors"));
    };
    var getErrorClassName = function () {
        if (getErrorMsg() !== "") {
            if (getErrorAttr() === "admin") {
                return "admin request";
            }
            else {
                return "request";
            }
        }
        return "";
    };
    var getInputClassName = function () {
        if (isText) {
            return "fullwidth noTextTransform";
        }
        else {
            return "fullwidth";
        }
    };
    return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ fullWidth: true, className: getErrorClassName(), variant: "outlined" }, { children: [_jsx(InputLabel, { children: label }), _jsx(OutlinedInput, { className: getInputClassName(), required: true, label: label, defaultValue: getValues(attr), onChange: handleChange, endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ edge: "end", onClick: handleError }, { children: _jsx(Warning, {}) })) })) }), _jsx(FormHelperText, { children: getErrorMsg() })] })) })));
};
