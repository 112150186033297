var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppProviders } from "@modules/core/context";
import { Colors } from "@modules/core/utils/styles";
import "./assets/css/app.less";
import { PasswordResetPage } from "./pages/unauthenticated/password-reset";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
var theme = createTheme({
    palette: {
        primary: { main: Colors.primary, contrastText: "#fff" },
        secondary: { main: Colors.accent, contrastText: "#fff" },
    },
    typography: {
        useNextVariants: true,
        fontFamily: ["roboto_condensedregular"].join(","),
        h5: {
            fontSize: 26,
            fontWeight: 300,
            color: "#8E8581",
        },
        h6: {
            fontSize: 22,
            fontWeight: "bold",
            color: "#8E8581",
        },
        subtitle1: {
            fontWeight: 600,
            color: "#8E8581",
        },
    },
    reviewColors: {
        ready: "#9DCC64",
        sync: "#9DCC64",
        review: "#c52845",
        request: "#4199FF",
    },
    spacing: 10,
    overrides: {
        MuiButton: {
            containedPrimary: {
                backgroundColor: Colors.primary,
                color: "#fff",
            },
            label: {
                fontSize: 16,
                fontWeight: "bold",
            },
            root: {
                borderRadius: 0,
            },
        },
        MuiExpansionPanel: {
            root: {
                marginBottom: 0,
                padding: 0,
            },
        },
        MuiFormControl: {
            root: {
                height: 28,
            },
        },
        MuiFormHelperText: {
            root: {
                fontFamily: "roboto_condensedregular",
                fontSize: 12,
                marginTop: 4,
            },
        },
        MuiFormLabel: {
            focused: {
                color: "#716162",
            },
            filled: {
                display: "none",
            },
            root: {
                color: "#716162",
                fontFamily: "roboto_condensedregular",
                fontSize: 16,
            },
        },
        MuiIcon: {
            colorAction: {
                color: "#9DCC64",
            },
        },
        MuiIconButton: {
            colorInherit: {
                color: "white,",
            },
            label: {
                color: "black",
                fontSize: 16,
                fontWeight: "bold",
            },
            root: {
                borderRadius: 0,
            },
        },
        MuiInput: {
            focused: {
                "&$underline:after, &$underline:before": {
                    borderBottom: "3px solid #e55a00",
                },
                "&:after": {
                    borderBottom: "3px solid #e55a00",
                },
            },
            formControl: {
                "label + &": {
                    marginTop: "0",
                },
            },
            root: {
                minHeight: 28,
            },
            underline: {
                "&:after": {
                    borderColor: "rgb(224, 224, 224)",
                },
                "&:before": {
                    borderBottom: "3px solid rgb(224, 224, 224)",
                    borderWidth: 0,
                    zIndex: 10,
                },
                "&:hover:not($disabled):not($focused):not($error):before": {
                    borderBottom: "3px solid rgb(224, 224, 224)",
                },
            },
        },
        MuiInputBase: {
            root: {
                fontSize: 15,
                lineHeight: 1,
                color: "#716162",
                fontFamily: "roboto_condensedregular",
            },
        },
        MuiInputLabel: {
            filled: {
                display: "none",
            },
            formControl: {
                top: 8,
                transform: "none",
            },
            root: {
                "&.with-value": {
                    display: "none",
                },
                pointerEvents: "none",
                top: 8,
                zIndex: 10,
            },
            shrink: {
                display: "none",
            },
        },
        MuiLinearProgress: {
            bar1Buffer: {
                height: 10,
            },
            bar2Buffer: {
                height: 10,
            },
            root: {
                height: 10,
            },
        },
        MuiListItem: {
            root: {
                paddingBottom: 4,
                paddingTop: 4,
            },
        },
        MuiMenuItem: {
            gutters: {
                paddingLeft: 7,
                paddingRight: 7,
            },
            root: {
                color: "#716162",
                fontFamily: "roboto_condensedregular",
            },
        },
        MuiPaper: {
            root: {
                marginBottom: 10,
                padding: 16,
            },
            rounded: {
                borderRadius: 2,
            },
        },
        MuiSelect: {
            select: {
                paddingLeft: 3,
            },
            selectMenu: {
                backgroundColor: "rgb(245, 246, 246)",
                minHeight: "auto",
            },
        },
    },
});
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsx(AppProviders, { children: _jsx(Router, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "*", element: _jsx(App, {}) }), _jsx(Route, { path: "/password-reset", element: _jsx(PasswordResetPage, {}) })] }) }) }) })) })) })) }), document.getElementById("app"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
