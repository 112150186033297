var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Switch } from "@mui/material";
import styled from "styled-components";
export var IOSSwitch = styled(Switch).attrs(function () { return ({
    classes: {
        root: "root",
        switchBase: "switchBase",
        thumb: "thumb",
        track: "track",
        checked: "checked",
        focusVisible: "focusVisible",
    },
    disableRipple: true,
    focusVisibleClassName: "focusVisible",
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &.root {\n    width: 42px;\n    height: 26px;\n    padding: 0;\n  }\n\n  .switchBase {\n    padding: 0px;\n    margin: 2px;\n    transitionduration: 300ms;\n    &.checked {\n      transform: translateX(16px);\n      color: #fff;\n      & + .track {\n        background-color: #65c466;\n        opacity: 1;\n        border: none;\n      }\n    }\n\n    &.focusVisible &.thumb {\n      color: #33cf4d;\n      border: 6x sold #fff;\n    }\n  }\n\n  .thumb {\n    box-sizing: \"border-box\";\n    width: 22px;\n    height: 22px;\n  }\n\n  & .track {\n    border-radius: 13px;\n    background-color: #e9e9ea;\n    opacity: 1;\n    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1),\n      border 500ms cubic-bezier(0.4, 0, 0.2, 1);\n  }\n\n  .checked {\n  }\n  .focusVisible {\n  }\n"], ["\n  &.root {\n    width: 42px;\n    height: 26px;\n    padding: 0;\n  }\n\n  .switchBase {\n    padding: 0px;\n    margin: 2px;\n    transitionduration: 300ms;\n    &.checked {\n      transform: translateX(16px);\n      color: #fff;\n      & + .track {\n        background-color: #65c466;\n        opacity: 1;\n        border: none;\n      }\n    }\n\n    &.focusVisible &.thumb {\n      color: #33cf4d;\n      border: 6x sold #fff;\n    }\n  }\n\n  .thumb {\n    box-sizing: \"border-box\";\n    width: 22px;\n    height: 22px;\n  }\n\n  & .track {\n    border-radius: 13px;\n    background-color: #e9e9ea;\n    opacity: 1;\n    transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1),\n      border 500ms cubic-bezier(0.4, 0, 0.2, 1);\n  }\n\n  .checked {\n  }\n  .focusVisible {\n  }\n"])));
var templateObject_1;
