var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FormControl, FormLabel, InputLabel, Select, MenuItem, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import { IOSSwitch } from "@modules/core/components";
export var DialogUser = function (props) {
    var _a, _b, _c, _d;
    var type = props.type, user = props.user, open = props.open, onClose = props.onClose, companies = props.companies;
    var _e = useForm({
        defaultValues: user,
    }), register = _e.register, watch = _e.watch, formState = _e.formState, handleSubmit = _e.handleSubmit, reset = _e.reset;
    useEffect(function () {
        reset(user);
    }, [user]);
    var handleOk = function (data) {
        onClose(data);
    };
    var handleCancel = function () {
        onClose();
    };
    return (_jsx(Dialog, __assign({ className: "userDialog", open: open }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(function (d) { return handleOk(d); }) }, { children: [_jsxs(DialogTitle, { children: [type === "add" ? "CREATE A NEW " : "EDIT", " USER"] }), _jsxs(DialogContent, { children: [_jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(TextField, __assign({}, register("name", {
                                required: "Please Enter Your Name!",
                                maxLength: 30,
                            }), { label: "NAME", helperText: (_b = (_a = formState === null || formState === void 0 ? void 0 : formState.errors) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.message, defaultValue: user === null || user === void 0 ? void 0 : user.name })) })), _jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(TextField, __assign({}, register("email", {
                                required: "Please Enter Your Email!",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Please Enter A Valid Email!",
                                },
                            }), { label: "E-MAIL", helperText: (_d = (_c = formState === null || formState === void 0 ? void 0 : formState.errors) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.message, defaultValue: user === null || user === void 0 ? void 0 : user.email })) })), _jsxs(FormControl, __assign({ className: "select", fullWidth: true }, { children: [_jsx(InputLabel, { children: "Company" }), _jsxs(Select, __assign({}, register("company"), { defaultValue: (user === null || user === void 0 ? void 0 : user.company.value) || "", label: "company", MenuProps: { PaperProps: { sx: { maxHeight: 230 } } } }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx("em", { children: "ALL" }) })), companies.map(function (company) {
                                            return (_jsx(MenuItem, __assign({ value: company.value }, { children: company.label }), uuidv4()));
                                        })] }))] })), _jsxs(FormControl, __assign({ className: "switch", fullWidth: true }, { children: [_jsx(IOSSwitch, __assign({}, register("isActive"), { defaultChecked: user === null || user === void 0 ? void 0 : user.isActive })), _jsx(FormLabel, { children: "ACTIVE" })] })), _jsxs(FormControl, __assign({ className: "switch", fullWidth: true }, { children: [_jsx(IOSSwitch, __assign({}, register("role"), { defaultChecked: (user === null || user === void 0 ? void 0 : user.role) === "admin" })), _jsx(FormLabel, { children: "ADMIN" })] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", autoFocus: true, onClick: handleCancel }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "contained", type: "submit" }, { children: type === "add" ? "ADD" : "SAVE" }))] })] })) })));
};
