var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { DataGrid, GridActionsCellItem, } from "@mui/x-data-grid";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Zoom, IconButton, Tooltip, Badge, Chip, LinearProgress, } from "@mui/material";
import { useHttp, useAuth, useUrlParams } from "@modules/core/hooks";
import { NoRowsOverlay, DialogRemove } from "@modules/core/components";
import { apiUrl } from "@modules/core/config";
export var List = function (_a) {
    var isLoading = _a.isLoading, products = _a.products, users = _a.users, columns = _a.columns;
    var user = useAuth().user;
    var queryClient = useQueryClient();
    var navigate = useNavigate();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), multiDelete = _c[0], setMultiDelete = _c[1];
    var _d = useState(0), deletedID = _d[0], setDeletedID = _d[1];
    var _e = useState([]), selectedRows = _e[0], setSelectedRows = _e[1];
    var client = useHttp();
    var requestParams = useUrlParams();
    var _f = useMutation(["deleteItem"], function () {
        return client("item/" + deletedID, { url: apiUrl, method: "DELETE" });
    }, {
        onSuccess: function () { return queryClient.invalidateQueries("products"); },
    }), mutateAsync = _f.mutateAsync, deleteItemLoading = _f.isLoading, error = _f.error;
    var _g = useMutation(["deleteItems"], function () {
        return client("list?ids=" + selectedRows, {
            url: apiUrl,
            method: "DELETE",
        });
    }, {
        onSuccess: function () {
            setSelectedRows([]);
            queryClient.invalidateQueries("products");
        },
    }), mutateMultiAsync = _g.mutateAsync, deleteItemsLoading = _g.isLoading, multiError = _g.error;
    var _h = useMutation(["saveItems"], function () {
        return client("list", {
            method: "POST",
            url: apiUrl,
            data: {
                ids: selectedRows,
                token: user === null || user === void 0 ? void 0 : user.token,
            },
        });
    }, {
        onSuccess: function (res) {
            setSelectedRows([]);
            queryClient.invalidateQueries("products");
        },
    }), saveItemsMutate = _h.mutateAsync, saveItemsError = _h.error, saveItemsLoading = _h.isLoading;
    var handleClickOpen = function (id) {
        setMultiDelete(false);
        setDeletedID(id);
        setOpen(true);
    };
    var handleMultiDeleteOpen = function () {
        setMultiDelete(true);
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleDelete = function (onlyClose) {
        if (onlyClose) {
            handleClose();
        }
        else {
            if (multiDelete) {
                mutateMultiAsync().then(function (x) {
                    handleClose();
                });
            }
            else {
                mutateAsync().then(function (x) {
                    handleClose();
                });
            }
        }
    };
    var getErrorList = function (list) {
        if (list && list.length > 0)
            return (_jsx("ul", { children: list.map(function (e) { return (_jsx("li", __assign({ className: e.attr }, { children: e.text }), uuidv4())); }) }));
        return "";
    };
    var handleOnSelectionClick = function (params) {
        setSelectedRows(params);
    };
    var uploadSelectedItems = function () {
        if (selectedRows.length > 0) {
            saveItemsMutate();
        }
    };
    return (_jsxs("div", __assign({ className: "product-list" }, { children: [_jsx(DialogRemove, { selectedid: deletedID, count: selectedRows.length, multi: multiDelete, open: open, products: products, onClose: handleDelete }), _jsx(Zoom, __assign({ in: selectedRows.length > 0 }, { children: _jsxs("div", __assign({ className: "actionSelectedItems" }, { children: [(user === null || user === void 0 ? void 0 : user.isAdmin) ? (_jsx(IconButton, __assign({ className: "backup", size: "small", onClick: uploadSelectedItems }, { children: _jsx(Badge, __assign({ badgeContent: selectedRows.length }, { children: _jsx(PublishIcon, {}) })) }))) : (""), _jsx(IconButton, __assign({ className: "backup delete", size: "small", onClick: handleMultiDeleteOpen }, { children: _jsx(Badge, __assign({ badgeContent: selectedRows.length }, { children: _jsx(DeleteIcon, {}) })) }))] })) })), _jsx(DataGrid, { checkboxSelection: true, isRowSelectable: function (params) { var _a, _b; return ["request", "review"].includes((_b = (_a = params.row) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.toLowerCase()); }, onSelectionModelChange: handleOnSelectionClick, hideFooterSelectedRowCount: true, loading: isLoading ||
                    saveItemsLoading ||
                    deleteItemsLoading ||
                    deleteItemLoading, rows: products, getRowId: function (row) { return row.trid; }, getRowClassName: function (params) {
                    return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
                }, components: {
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: NoRowsOverlay,
                }, columns: [
                    {
                        headerName: "ACTIONS",
                        field: "actions",
                        type: "actions",
                        sortable: false,
                        getActions: function (params) {
                            if (params.row.status !== "ready") {
                                return [
                                    _jsx(GridActionsCellItem, { icon: _jsx(EditIcon, {}), onClick: function () {
                                            return navigate("/products/" + params.row.trid + requestParams, {
                                                replace: true,
                                            });
                                        }, label: "EDIT" }),
                                    _jsx(GridActionsCellItem, { icon: _jsx(DeleteIcon, {}), onClick: function () { return handleClickOpen(params.row.trid); }, label: "DELETE" }),
                                ];
                            }
                            else {
                                return [];
                            }
                        },
                    },
                    {
                        headerName: "STATUS",
                        field: "status",
                        sortable: true,
                        align: "center",
                        width: 88,
                        renderCell: function (params) {
                            var _a;
                            return (_jsx(Tooltip, __assign({ title: getErrorList(params === null || params === void 0 ? void 0 : params.row.errors), placement: "bottom-end" }, { children: _jsx(Badge, __assign({ badgeContent: (_a = params === null || params === void 0 ? void 0 : params.row.errors) === null || _a === void 0 ? void 0 : _a.length, color: "secondary" }, { children: _jsx(Chip, { size: "small", className: params === null || params === void 0 ? void 0 : params.value, label: params === null || params === void 0 ? void 0 : params.value }) })) })));
                        },
                    },
                    {
                        headerName: "COUNTRY",
                        field: "country",
                        sortable: true,
                        align: "center",
                        width: 68,
                        renderCell: function (params) {
                            return (_jsx(Avatar, { src: "./client/img/" + (params === null || params === void 0 ? void 0 : params.value) + ".png", variant: "rounded" }));
                        },
                    },
                    {
                        headerName: "USER",
                        field: "user",
                        width: 150,
                    },
                    {
                        headerName: "EAN",
                        field: "ean",
                        width: 150,
                    },
                    {
                        headerName: "Phononet Nr",
                        field: "phononet_nr",
                        width: 250,
                        renderCell: function (params) {
                            var _a, _b;
                            return ((_a = params === null || params === void 0 ? void 0 : params.value) === null || _a === void 0 ? void 0 : _a.label) + " [" + ((_b = params === null || params === void 0 ? void 0 : params.value) === null || _b === void 0 ? void 0 : _b.value) + "]";
                        },
                    },
                    {
                        headerName: "Art-Nr",
                        field: "artnr",
                        width: 150,
                    },
                    {
                        headerName: "Künstler",
                        field: "artist",
                        width: 150,
                        cellClassName: "noTextTransform",
                    },
                    {
                        headerName: "TITEL",
                        field: "title",
                        width: 300,
                        cellClassName: "noTextTransform",
                    },
                    {
                        headerName: "LABEL",
                        field: "label",
                        width: 250,
                    },
                    {
                        headerName: "Tonträger-Art",
                        field: "tt",
                        width: 300,
                        renderCell: function (params) {
                            var _a, _b;
                            return ((_a = params === null || params === void 0 ? void 0 : params.value) === null || _a === void 0 ? void 0 : _a.label) + " [" + ((_b = params === null || params === void 0 ? void 0 : params.value) === null || _b === void 0 ? void 0 : _b.value) + "]";
                        },
                    },
                    {
                        headerName: "Produktion",
                        field: "production",
                        width: 150,
                        renderCell: function (params) {
                            var _a;
                            return (_a = params === null || params === void 0 ? void 0 : params.value) === null || _a === void 0 ? void 0 : _a.label;
                        },
                    },
                    {
                        headerName: "Programmart",
                        field: "programmart",
                        width: 300,
                        renderCell: function (params) {
                            var _a, _b;
                            return ((_a = params === null || params === void 0 ? void 0 : params.value) === null || _a === void 0 ? void 0 : _a.label) + " [" + ((_b = params === null || params === void 0 ? void 0 : params.value) === null || _b === void 0 ? void 0 : _b.value) + "]";
                        },
                    },
                    {
                        headerName: "Set-Inhalt",
                        field: "set",
                    },
                    {
                        headerName: "Abgabepreis",
                        field: "price",
                    },
                    {
                        headerName: "VÖ",
                        field: "voe",
                    },
                    {
                        headerName: "CREATED",
                        field: "created",
                        width: 180,
                    },
                ] })] })));
};
