var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { http } from "@modules/core/utils/http";
import { useAuth } from "./Auth";
export var useHttp = function () {
    var user = useAuth().user;
    return function () {
        var _a = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            _a[_i] = arguments[_i];
        }
        var endpoint = _a[0], config = _a[1];
        return http(endpoint, __assign(__assign({}, config), { token: user === null || user === void 0 ? void 0 : user.token }));
    };
};
