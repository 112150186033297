import { useEffect, useRef } from "react";
export var useDocumentTitle = function (title, keepOnUnmount) {
    if (keepOnUnmount === void 0) { keepOnUnmount = true; }
    var oldTitle = useRef(document.title).current;
    useEffect(function () {
        document.title = title;
    }, [title]);
    useEffect(function () {
        return function () {
            if (!keepOnUnmount) {
                document.title = oldTitle;
            }
        };
    }, [title, keepOnUnmount]);
};
