var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { List } from "./list";
import { useQuery } from "react-query";
import { useHttp, useDebounce, useDocumentTitle, useUrlQueryParams, } from "@modules/core/hooks";
import { LoadingIndicator } from "@modules/core/components";
import { searchParamsAPI, apiUrl } from "@modules/core/config";
export var ProductsPage = function () {
    useDocumentTitle("Dashboard", false);
    var _a = useUrlQueryParams(searchParamsAPI), param = _a[0], setParam = _a[1];
    var debouncedParam = useDebounce(param, 500);
    var client = useHttp();
    var _b = useQuery(["products", debouncedParam], function () {
        return client("list", { url: apiUrl, data: debouncedParam });
    }), data = _b.data, isLoading = _b.isLoading, isError = _b.isError, status = _b.status;
    return isLoading ? (_jsx("div", { children: _jsx(LoadingIndicator, { pageCentered: true, text: "Loading" }) })) : (_jsx("div", __assign({ className: "no-zindex product-list" }, { children: isError ? (_jsx("div", { children: "Error fetching data" })) : (_jsx(List, { isLoading: isLoading, products: (data === null || data === void 0 ? void 0 : data.payload) || [] })) })));
};
