var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
var ScrollRecognizer = /** @class */ (function (_super) {
    __extends(ScrollRecognizer, _super);
    function ScrollRecognizer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isScrolled: false,
        };
        _this.scrollStepSize = 200;
        _this.scrollSteps = 0;
        _this.handleScroll = function () {
            var scrolled = false;
            if (window.pageYOffset > _this.props.scrollLimit) {
                scrolled = true;
            }
            var scrollSteps = Math.abs(window.pageYOffset / 50);
            _this.removeScrollSteps(scrollSteps);
            _this.addScrollSteps(scrollSteps);
            _this.scrollSteps = scrollSteps;
            if (_this.state.isScrolled !== scrolled) {
                _this.setState({
                    isScrolled: scrolled,
                });
            }
        };
        return _this;
    }
    ScrollRecognizer.prototype.componentDidMount = function () {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    };
    ScrollRecognizer.prototype.componentWillUnmount = function () {
        window.removeEventListener("scroll", this.handleScroll);
        this.removeScrollSteps(0);
    };
    ScrollRecognizer.prototype.render = function () {
        var scrollClass = classnames("scroll-resolver", { scrolled: this.state.isScrolled }, this.props.className);
        return _jsx("div", __assign({ className: scrollClass }, { children: this.props.children }));
    };
    ScrollRecognizer.prototype.removeScrollSteps = function (scrollSteps) {
        for (var i = 1; i <= this.scrollSteps; i++) {
            var stepSize = i * this.scrollStepSize;
            if (i > scrollSteps) {
                document.body.classList.remove("scroll-step-".concat(stepSize));
            }
        }
    };
    ScrollRecognizer.prototype.addScrollSteps = function (scrollSteps) {
        for (var i = 1; i <= scrollSteps; i++) {
            if (i > this.scrollSteps) {
                document.body.classList.add("scroll-step-".concat(i * this.scrollStepSize));
            }
        }
    };
    ScrollRecognizer.defaultProps = {
        scrollLimit: 50,
    };
    return ScrollRecognizer;
}(React.Component));
export { ScrollRecognizer };
