var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePopupState, bindTrigger, bindMenu, } from "material-ui-popup-state/hooks";
import { useUrlQueryParams } from "@modules/core/hooks";
export function MenuButton(props) {
    var name = props.name, defaultLabel = props.defaultLabel, children = props.children;
    var _a = useUrlQueryParams([
        "title",
        "state",
        "sort",
        "open",
        "country",
    ]), searchParams = _a[0], setSearchParams = _a[1];
    var initialState = function () {
        var _a;
        var label = defaultLabel;
        if (searchParams === null || searchParams === void 0 ? void 0 : searchParams[name]) {
            if (children.find(function (item) { return item.value === (searchParams === null || searchParams === void 0 ? void 0 : searchParams[name]); })) {
                return (((_a = children.find(function (item) { return item.value === (searchParams === null || searchParams === void 0 ? void 0 : searchParams[name]); })) === null || _a === void 0 ? void 0 : _a.label) ||
                    defaultLabel);
            }
        }
        return label;
    };
    var _b = useState(initialState()), stateLabel = _b[0], setStateLabel = _b[1];
    var popupState = usePopupState({ variant: "popover", popupId: name });
    var selectItem = function (item) {
        var _a;
        setSearchParams((_a = {}, _a[name] = item.value, _a));
        setStateLabel(item.label);
        popupState.close();
    };
    return (_jsxs("div", { children: [_jsx(Button, __assign({ variant: "contained", endIcon: _jsx(ArrowDropDownIcon, {}), disableElevation: true }, bindTrigger(popupState), { children: stateLabel || defaultLabel })), _jsx(Menu, __assign({}, bindMenu(popupState), { anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" } }, { children: children.map(function (item) {
                    return (_jsx(MenuItem, __assign({ onClick: function () { return selectItem(item); } }, { children: item.label }), item.value));
                }) }))] }));
}
