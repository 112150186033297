var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Footer.prototype.render = function () {
        return (_jsx("div", __assign({ className: "footer" }, { children: _jsxs("div", __assign({ className: "container" }, { children: [_jsxs("div", __assign({ className: "footer--left" }, { children: [_jsx("img", { className: "logo", src: "./client/img/gfk_logo_48.png" }), " ", _jsx("a", __assign({ href: "/imprint" }, { children: "Imprint" }))] })), _jsxs("div", __assign({ className: "footer--right" }, { children: [this.props.version && (_jsxs("span", __assign({ className: "version" }, { children: ["v", this.props.version] }))), _jsxs("span", __assign({ className: "copyright" }, { children: ["Copyright \u00A9 ", new Date().getFullYear(), " GfK Entertainment. All Rights Reserved."] }))] }))] })) })));
    };
    return Footer;
}(React.Component));
export { Footer };
