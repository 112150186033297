var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var isFalsy = function (value) { return (value === 0 ? false : !value); };
export var isVoid = function (value) {
    return value === undefined || value === null || value === "";
};
export var cleanObject = function (object) {
    var result = __assign({}, object);
    Object.keys(result).forEach(function (key) {
        var value = result[key];
        if (isVoid(value)) {
            delete result[key];
        }
    });
    return result;
};
