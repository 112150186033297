import { useRef, useEffect } from "react";
/**
 * return the montued state of component
 * if component unmounted return false
 */
export var useMountedRef = function () {
    var mountedRef = useRef(false);
    useEffect(function () {
        mountedRef.current = true;
        return function () {
            mountedRef.current = false;
        };
    });
    return mountedRef;
};
