var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
var ReviewSquare = /** @class */ (function (_super) {
    __extends(ReviewSquare, _super);
    function ReviewSquare() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * render component
     */
    ReviewSquare.prototype.render = function () {
        var className = classnames("review-square", "review-square--" + this.props.status, {
            "review-square--text": Boolean(this.props.children),
        });
        return (_jsx("div", __assign({ className: className, title: this.getStatusTitle(), style: this.props.style }, { children: this.props.children })));
    };
    ReviewSquare.prototype.getStatusTitle = function () {
        switch (this.props.status) {
            case "sync":
                return (this.props.syncTitle || "Item will be synchronized with database soon");
            case "ready":
                return this.props.readyTitle || "Item is synchronized with database";
            case "request":
                return this.props.requestTitle || "Item needs attention";
            case "review":
                return this.props.reviewTitle || "Item is in review";
            default:
                return "";
        }
    };
    ReviewSquare.defaultProps = {};
    return ReviewSquare;
}(React.Component));
export { ReviewSquare };
