var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, InputLabel, InputAdornment, Drawer, Zoom, Card, CardActions, CardContent, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, FormControl, FormLabel, Select, MenuItem, Tooltip, } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "react-query";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { IOSSwitch, LoadingIndicator, DialogUser, } from "@modules/core/components";
import { useAuth, useHttp, useDocumentTitle } from "@modules/core/hooks";
import { apiUrl } from "@modules/core/config";
export var UserList = function () {
    var _a;
    useDocumentTitle("Benutzerverwaltung");
    var _b = useQuery(["userlist"], function () {
        return client("users", { url: apiUrl });
    }, {
        onSuccess: function (userlist) {
            setUsers(userlist);
        },
    }), data = _b.data, isLoading = _b.isLoading, isError = _b.isError, status = _b.status;
    var user = useAuth().user;
    var _c = useState({ targetUser: "", lockState: true }), lock = _c[0], setLock = _c[1];
    var _d = useState(false), open = _d[0], setOpen = _d[1];
    var _e = useState("add"), type = _e[0], setType = _e[1];
    var _f = useState(false), openDialogUser = _f[0], setOpenDialogUser = _f[1];
    var _g = useState(true), appear = _g[0], setAppear = _g[1];
    var _h = useState(data), users = _h[0], setUsers = _h[1];
    var _j = useState(), selectedUser = _j[0], setSelectedUser = _j[1];
    var _k = useState(""), deletedID = _k[0], setDeletedID = _k[1];
    var client = useHttp();
    var _l = useForm({
        mode: "onChange",
    }), register = _l.register, watch = _l.watch, handleSubmit = _l.handleSubmit;
    var _m = useQuery("companies", function () {
        return client("options/companies", {
            url: apiUrl,
        });
    }), companyData = _m.data, loadingCompany = _m.isLoading;
    // const { mutateAsync, error, isLoading } = useMutation<ResDefault>(
    //     ["setActive"],
    //     () => {
    //       return client("lockUser", {
    //         method: "POST",
    //         url: apiUrl,
    //         data: {
    //           ...lock,
    //           userToken: user?.token,
    //         },
    //       });
    //     },
    //     {
    //       onSuccess: (res) => {
    //         if (res.status) {
    //
    //         }
    //       },
    //     }
    // );
    var setActive = function (id, val) {
        setLock({ targetUser: id, lockState: val });
    };
    var handleClickOpen = function (id) {
        setAppear(false);
        setDeletedID(id);
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var deleteItem = function () {
        // mutateAsync().then((x) => {
        //   handleClose();
        // });
    };
    var getRole = function (active, role) {
        if (active) {
            if (role === "admin")
                return "admin";
            return "active";
        }
        return "";
    };
    var checkSearch = function (user) {
        var _a = watch(), search = _a.search, role = _a.role, company = _a.company, isActive = _a.isActive;
        return ((search === "" ||
            user.name.toLowerCase().includes(search.toLowerCase())) &&
            (role === "" || user.role === role) &&
            (company === "" || user.company.value === company) &&
            (isActive === false || user.isActive === isActive));
    };
    var changeForm = function () {
        setUsers(data === null || data === void 0 ? void 0 : data.filter(function (user) { return checkSearch(user); }));
    };
    var handleUser = function (user, type) {
        setType(type);
        setAppear(false);
        if (type === "add") {
            setSelectedUser(__assign(__assign({}, user), { name: "", email: "" }));
        }
        else {
            setSelectedUser(user);
        }
        setOpenDialogUser(true);
    };
    var handleDialogUserClose = function (user) {
        setOpenDialogUser(false);
        console.log(user);
    };
    return isLoading ? (_jsx("div", { children: _jsx(LoadingIndicator, { pageCentered: true, text: "Loading" }) })) : (_jsxs("div", __assign({ className: "user-list" }, { children: [_jsxs(Dialog, __assign({ open: open, onClose: handleClose }, { children: [_jsx(DialogTitle, __assign({ sx: { color: "#c52845", fontSize: "18px" }, id: "alert-dialog-title" }, { children: "REMOVE USER" })), _jsxs(DialogContent, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: "Are you sure you want to remove this user?" })), _jsx(Typography, __assign({ sx: { color: "#c52845" }, variant: "subtitle2" }, { children: (_a = data === null || data === void 0 ? void 0 : data.find(function (item) { return item.id.toString() === deletedID.toString(); })) === null || _a === void 0 ? void 0 : _a.name }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ sx: { color: "rgba(0,0,0,0.7)" }, onClick: handleClose, autoFocus: true }, { children: "CANCEL" })), _jsx(Button, __assign({ sx: { color: "#c52845" }, onClick: deleteItem }, { children: "OK" }))] })] })), _jsx(DialogUser, { type: type, open: openDialogUser, onClose: handleDialogUserClose, companies: (companyData === null || companyData === void 0 ? void 0 : companyData.payload) || [], user: selectedUser }), _jsx(Drawer, __assign({ variant: "persistent", anchor: "left", open: true }, { children: _jsxs("form", __assign({ className: "searchUser", onSubmit: handleSubmit(function (d) { return console.log(d); }) }, { children: [_jsx(FormControl, __assign({ fullWidth: true }, { children: _jsx(Input, __assign({ fullWidth: true, size: "small" }, register("search", {
                                onChange: function (e) {
                                    changeForm();
                                },
                            }), { startAdornment: _jsx(InputAdornment, __assign({ position: "start" }, { children: _jsx(PersonSearchIcon, {}) })) })) })), _jsxs(FormControl, __assign({ variant: "standard", fullWidth: true }, { children: [_jsx(InputLabel, { children: "Role" }), _jsxs(Select, __assign({}, register("role", {
                                    onChange: function (e) {
                                        changeForm();
                                    },
                                }), { defaultValue: "", label: "Role" }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx("em", { children: "ALL" }) })), _jsx(MenuItem, __assign({ value: "admin" }, { children: "ADMIN" })), _jsx(MenuItem, __assign({ value: "user" }, { children: "USER" }))] }))] })), _jsxs(FormControl, __assign({ variant: "standard", fullWidth: true }, { children: [_jsx(InputLabel, { children: "Company" }), _jsxs(Select, __assign({}, register("company", {
                                    onChange: function (e) {
                                        changeForm();
                                    },
                                }), { defaultValue: "", label: "company", MenuProps: { PaperProps: { sx: { maxHeight: 230 } } } }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx("em", { children: "ALL" }) })), companyData === null || companyData === void 0 ? void 0 : companyData.payload.map(function (company) {
                                            return (_jsx(MenuItem, __assign({ value: company.value }, { children: company.label }), uuidv4()));
                                        })] }))] })), _jsxs(FormControl, __assign({ fullWidth: true, sx: { paddingTop: "1rem" } }, { children: [_jsx(IOSSwitch, __assign({}, register("isActive", {
                                    onChange: function (e) {
                                        changeForm();
                                    },
                                }), { defaultChecked: false })), _jsx(FormLabel, { children: "ONLY ACTIVE USERS" })] }))] })) })), _jsx("div", __assign({ className: "cards" }, { children: users === null || users === void 0 ? void 0 : users.map(function (user, index) {
                    return (_jsx(Zoom, __assign({ appear: appear, in: user && user.id !== "", timeout: index * 250 }, { children: _jsxs(Card, { children: [_jsxs(CardContent, __assign({ className: getRole(user.isActive, user.role) }, { children: [_jsx("div", { children: _jsx("img", { src: user.role === "admin"
                                                    ? "/client/img/admin.png"
                                                    : "/client/img/user.png" }) }), _jsx(IOSSwitch, { defaultChecked: user.isActive, sx: { position: "absolute", right: 6, top: 6 } }), _jsx(Typography, { children: user.name }), _jsx(Typography, __assign({ variant: "body2" }, { children: user.company.label }))] })), _jsx("div", __assign({ className: "CardInfo" }, { children: _jsxs("ul", { children: [_jsxs("li", { children: [_jsx(Typography, { children: "Role" }), _jsx(Typography, __assign({ variant: "body2" }, { children: user.refRole }))] }), _jsxs("li", { children: [_jsx(Typography, { children: "Email" }), _jsx(Typography, __assign({ variant: "body2" }, { children: user.email }))] }), _jsxs("li", { children: [_jsx(Typography, { children: "Last login at" }), _jsx(Typography, __assign({ variant: "body2" }, { children: new Date(user.login)
                                                            .toISOString()
                                                            .slice(0, -5)
                                                            .replace("T", "  ") }))] })] }) })), _jsxs(CardActions, { children: [_jsx(Tooltip, __assign({ title: "Send a Password request Email" }, { children: _jsx(IconButton, { children: _jsx(ContactMailIcon, {}) }) })), _jsx(Tooltip, __assign({ title: "edit user profile" }, { children: _jsx(IconButton, __assign({ onClick: function () { return handleUser(user, "edit"); } }, { children: _jsx(ManageAccountsIcon, {}) })) })), _jsx(Tooltip, __assign({ title: "Create a Duplicate User" }, { children: _jsx(IconButton, __assign({ onClick: function () { return handleUser(user, "add"); } }, { children: _jsx(PersonAddIcon, {}) })) })), _jsx(Tooltip, __assign({ title: "Remove this user" }, { children: _jsx(IconButton, __assign({ onClick: function () { return handleClickOpen(user === null || user === void 0 ? void 0 : user.id); } }, { children: _jsx(PersonRemoveIcon, {}) })) }))] })] }) }), uuidv4()));
                }) }))] })));
};
