var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { EditUI } from "./edit";
import { useHttp, useDocumentTitle } from "@modules/core/hooks";
import { LoadingIndicator } from "@modules/core/components";
import { apiUrl } from "@modules/core/config";
export var ProductPage = function () {
    useDocumentTitle("EDIT Product");
    var params = useParams();
    var navigate = useNavigate();
    var client = useHttp();
    var _a = useQuery(["products", params], function () {
        return client("item/" + params.trid, { url: apiUrl });
    }, {
        onError: function (err) {
            console.log(err);
        },
        // enabled: Boolean(params.trid),
    }), data = _a.data, status = _a.status, remove = _a.remove, isSuccess = _a.isSuccess;
    useEffect(function () {
        return function () {
            remove();
        };
    }, []);
    return isSuccess ? (_jsx("div", __assign({ className: "product-edit" }, { children: data && (data === null || data === void 0 ? void 0 : data.payload) && _jsx(EditUI, { data: data === null || data === void 0 ? void 0 : data.payload }) }))) : (_jsx("div", { children: _jsx(LoadingIndicator, { pageCentered: true, text: "Loading" }) }));
};
