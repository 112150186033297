var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TextField, Alert, Avatar, Paper, ToggleButton, ToggleButtonGroup, IconButton, Button, ButtonGroup, Grid, Slide, } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { FormReviewSelect, LoadingIndicator } from "@modules/core/components";
import { useAuth, useHttp, useDocumentTitle, useUrlParams, } from "@modules/core/hooks";
import { apiUrl } from "@modules/core/config";
export var ProductAddPage = function () {
    var _a, _b, _c;
    useDocumentTitle("ADD Product");
    var params = useParams();
    var navigate = useNavigate();
    var requestParams = useUrlParams();
    var user = useAuth().user;
    var client = useHttp();
    var productionList = [
        {
            key: "1",
            value: "I",
            label: "INTERNATIONAL",
        },
        {
            key: "2",
            value: "N",
            label: "NATIONAL",
        },
    ];
    var _d = useQuery("companies", function () {
        return client("options/companies", {
            url: apiUrl,
        });
    }), companyData = _d.data, companyDataLoading = _d.isLoading, companyDataSuccess = _d.isSuccess;
    var _e = useQuery("mediaFormats", function () {
        return client("options/media_formats", {
            url: apiUrl,
        });
    }), mediaFormatsData = _e.data, mediaFormatLoading = _e.isLoading, mediaFormatSuccess = _e.isSuccess;
    var _f = useQuery("genres", function () {
        return client("options/genres", {
            url: apiUrl,
        });
    }), programmartData = _f.data, programmartLoading = _f.isLoading, programmartSuccess = _f.isSuccess;
    var _g = useQuery("bundles", function () {
        return client("options/bundles", {
            url: apiUrl,
        });
    }), bundlesData = _g.data, bundlesLoading = _g.isLoading, bundlesSuccess = _g.isSuccess;
    var _h = useMutation(["addItem"], function () {
        var _a, _b, _c, _d, _e;
        return client("item", {
            method: "POST",
            url: apiUrl,
            data: __assign(__assign({}, getValues()), { tt: (_a = getValues("tt")) === null || _a === void 0 ? void 0 : _a.value, production: (_b = getValues("production")) === null || _b === void 0 ? void 0 : _b.value, programmart: (_c = getValues("programmart")) === null || _c === void 0 ? void 0 : _c.value, phononet_nr: (_d = getValues("phononet_nr")) === null || _d === void 0 ? void 0 : _d.value, bundles: (_e = getValues("bundles")) === null || _e === void 0 ? void 0 : _e.value, token: user === null || user === void 0 ? void 0 : user.token }),
        });
    }, {
        onSuccess: function (res) {
            if (res.status) {
                navigate("/products", { replace: true });
            }
            else {
                setValue("errors", [
                    // @ts-ignore
                    (res === null || res === void 0 ? void 0 : res.message) || "Failed to connect to MySQL: Too many connections",
                ]);
            }
        },
    }), mutateAsync = _h.mutateAsync, error = _h.error, isLoading = _h.isLoading;
    var item = {
        add: false,
        artist: "",
        artnr: 0,
        ean: "",
        errors: [],
        label: "",
        phononet_nr: {},
        price: 0,
        bundles: {},
        production: {},
        programmart: {},
        set: 0,
        countries: ["de"],
        status: "review",
        title: "",
        trid: 0,
        tt: {},
        voe: dayjs().format("YYYYMMDD"),
    };
    var alertStyle = {
        marginTop: "1rem",
        marginBottom: "1rem",
    };
    var _j = useForm({
        mode: "onChange",
        defaultValues: item,
    }), watch = _j.watch, register = _j.register, handleSubmit = _j.handleSubmit, setValue = _j.setValue, getValues = _j.getValues, control = _j.control, _k = _j.formState, errors = _k.errors, isValid = _k.isValid;
    var selectCountry = function (country) {
        var list = getValues("countries") || [];
        if (list.find(function (x) { return x === country; })) {
            if (list.length > 1) {
                setValue("countries", list.filter(function (x) { return x !== country; }));
            }
        }
        else {
            setValue("countries", __spreadArray(__spreadArray([], list, true), [country], false));
        }
    };
    var doSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            mutateAsync();
            return [2 /*return*/];
        });
    }); };
    return isLoading ||
        companyDataLoading ||
        mediaFormatLoading ||
        bundlesLoading ||
        programmartLoading ? (_jsx("div", { children: _jsx(LoadingIndicator, { pageCentered: true, text: "Loading" }) })) : (_jsx(Slide, __assign({ direction: "up", in: companyDataSuccess && mediaFormatSuccess && programmartSuccess, mountOnEnter: true, unmountOnExit: true }, { children: _jsxs("div", __assign({ className: "content content--column product-form" }, { children: [(_a = watch("errors")) === null || _a === void 0 ? void 0 : _a.map(function (e) {
                    return (_jsx(Alert, __assign({ style: alertStyle, severity: "error" }, { children: e }), uuidv4()));
                }), _jsx("form", __assign({ onSubmit: handleSubmit(function (data) {
                        doSubmit(data);
                    }) }, { children: _jsxs(Paper, __assign({ sx: { padding: "2rem" } }, { children: [item
                                ? (_b = item.errors) === null || _b === void 0 ? void 0 : _b.map(function (e) {
                                    return (_jsx(Alert, __assign({ style: alertStyle, severity: "error" }, { children: e })));
                                })
                                : "", _jsx("h2", { children: _jsx("span", { children: "CREATE NEW ARTICLE" }) }), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("ean", {
                                            required: "This field is required.",
                                            maxLength: {
                                                value: 13,
                                                message: "Minimum 13 letters required..",
                                            },
                                        }), { className: "fullwidth", required: true, id: "outlined-required", label: "EAN" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("artnr"), { className: "fullwidth", required: true, id: "outlined-required", label: "ART-NR" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("artist"), { className: "fullwidth noTextTransform", required: true, id: "outlined-required", label: "K\u00DCNSTLER" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("title"), { className: "fullwidth noTextTransform", required: true, id: "outlined-required", label: "TITEL" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("set"), { className: "fullwidth", required: true, id: "outlined-required", label: "SET-INHALT" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("price"), { className: "fullwidth", required: true, id: "outlined-required", label: "ABGABEPREIS (\u20AC)" })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DatePicker, __assign({}, register("voe"), { className: "fullwidth", value: watch("voe"), onChange: function (newValue) {
                                                setValue("voe", (newValue === null || newValue === void 0 ? void 0 : newValue.format("YYYYMMDD")) || "");
                                            }, inputFormat: "YYYYMMDD", label: "V\u00D6", renderInput: function (params) { return _jsx(TextField, __assign({}, params)); } })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(TextField, __assign({}, register("label"), { className: "fullwidth", required: true, id: "outlined-required", label: "LABEL" })) })), _jsx(FormReviewSelect, { control: control, setValue: setValue, watch: watch, attr: "phononet_nr", label: "PHONONET NR", withReview: false, options: (companyData === null || companyData === void 0 ? void 0 : companyData.payload) || [] }), _jsx(FormReviewSelect, { control: control, setValue: setValue, watch: watch, attr: "tt", label: "TONTRÄGER-ART", withReview: false, options: (mediaFormatsData === null || mediaFormatsData === void 0 ? void 0 : mediaFormatsData.payload) || [] }), _jsx(FormReviewSelect, { control: control, setValue: setValue, watch: watch, attr: "production", label: "PRODUKTION", withReview: false, options: productionList || [] }), _jsx(FormReviewSelect, { control: control, setValue: setValue, watch: watch, attr: "programmart", label: "PROGRAMMART", withReview: false, options: (programmartData === null || programmartData === void 0 ? void 0 : programmartData.payload) || [] }), ((_c = watch("countries")) === null || _c === void 0 ? void 0 : _c.includes("de")) ? (_jsx(FormReviewSelect, { control: control, setValue: setValue, watch: watch, attr: "bundles", label: "ART DES BUNDLES", withReview: false, options: (bundlesData === null || bundlesData === void 0 ? void 0 : bundlesData.payload) || [] })) : (""), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(Controller, { name: "status", control: control, defaultValue: item.status, render: function (_a) {
                                                var field = _a.field;
                                                return (_jsxs(ToggleButtonGroup, __assign({ fullWidth: true, exclusive: true }, field, { children: [_jsx(ToggleButton, __assign({ className: "request", value: "request" }, { children: "REQUEST" })), _jsx(ToggleButton, __assign({ className: "review", value: "review" }, { children: "REVIEW" })), (user === null || user === void 0 ? void 0 : user.isAdmin) ? (_jsx(ToggleButton, __assign({ className: "ready", value: "ready" }, { children: "READY" }))) : ("")] })));
                                            } }) }))] })), _jsxs("div", __assign({ className: "button-bar with-country" }, { children: [_jsx("div", { children: _jsx(Controller, { name: "countries", control: control, defaultValue: item.countries, render: function (_a) {
                                                var _b, _c, _d;
                                                var field = _a.field;
                                                return (_jsxs(ButtonGroup, { children: [_jsx(IconButton, __assign({ onClick: function () { return selectCountry("de"); } }, { children: ((_b = getValues("countries")) === null || _b === void 0 ? void 0 : _b.includes("de")) ? (_jsx(Avatar, { src: "./client/img/de.png" })) : (_jsx(Avatar, { src: "./client/img/de_disable.png" })) })), _jsx(IconButton, __assign({ onClick: function () { return selectCountry("ch"); } }, { children: ((_c = getValues("countries")) === null || _c === void 0 ? void 0 : _c.includes("ch")) ? (_jsx(Avatar, { src: "./client/img/ch.png" })) : (_jsx(Avatar, { src: "./client/img/ch_disable.png" })) })), _jsx(IconButton, __assign({ onClick: function () { return selectCountry("at"); } }, { children: ((_d = getValues("countries")) === null || _d === void 0 ? void 0 : _d.includes("at")) ? (_jsx(Avatar, { src: "./client/img/at.png" })) : (_jsx(Avatar, { src: "./client/img/at_disable.png" })) }))] }));
                                            } }) }), _jsxs("div", { children: [_jsx(Button, __assign({ startIcon: _jsx(ArrowBackIcon, {}), variant: "outlined", onClick: function () {
                                                    return navigate("/products" + requestParams, { replace: true });
                                                } }, { children: "BACK TO LIST" })), _jsxs(LoadingButton, __assign({ className: watch("status"), variant: "contained", loading: isLoading, disabled: !isValid, type: "submit", startIcon: _jsx(NoteAddIcon, {}) }, { children: ["CREATE ARTICLE AS ", watch("status")] }))] })] }))] })) }))] })) })));
};
