var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { AuthenticatedRoutes } from "./routes";
import { UnauthenticatedPage } from "./pages";
import { PageNavbar } from "./layouts/header";
import { ProductsNavbar } from "./layouts/header/ProductsNavbar";
import { useAuth } from "@modules/core/hooks";
function App() {
    var location = useLocation();
    var user = useAuth().user;
    var showProductsNavbar = function () {
        if (user && ["/products"].includes(location.pathname)) {
            return true;
        }
        return false;
    };
    var containerClass = classnames("container", {
        signin: !user,
        page: !user || location.pathname !== "/new",
        products: user && ["/products", "/upload", "/admin"].includes(location.pathname),
        admin: user && ["/admin"].includes(location.pathname),
    });
    return (_jsxs("section", __assign({ className: user ? "" : "loginSection" }, { children: [user ? _jsx(PageNavbar, {}) : "", showProductsNavbar() ? _jsx(ProductsNavbar, {}) : "", _jsx("div", __assign({ className: containerClass }, { children: user ? _jsx(AuthenticatedRoutes, {}) : _jsx(UnauthenticatedPage, {}) }))] })));
}
export default App;
