import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { PasswordForgotPage } from "./password-forgot";
import { LoginPage } from "./login";
import { useDocumentTitle } from "@modules/core/hooks";
export var UnauthenticatedPage = function () {
    var _a = useState(true), isLogin = _a[0], setIsLogin = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    useDocumentTitle("Login");
    return (_jsx("div", { children: isLogin ? (_jsx(LoginPage, { setIsLogin: setIsLogin, onError: setError })) : (_jsx(PasswordForgotPage, { setIsLogin: setIsLogin, onError: setError })) }));
};
