var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { IconButton } from "@modules/core/components/IconButton";
import Chip from "@mui/material/Chip";
var ProductUpdateHeadline = /** @class */ (function (_super) {
    __extends(ProductUpdateHeadline, _super);
    function ProductUpdateHeadline() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * render component
     */
    ProductUpdateHeadline.prototype.render = function () {
        return (_jsxs("h2", { children: [_jsx("span", { children: this.props.title }), _jsx(Chip, { className: this.props.reviewState, label: this.props.reviewState, clickable: false }), _jsx("span", { className: "flex" }), this.props.showHistoryBtn && (_jsx(IconButton, { tooltip: "Show History", small: true, name: "book", onClick: this.props.onShowHistoryClick })), this.props.showImportBtn && (_jsx(IconButton, { tooltip: "Import Articles", small: true, name: "import_export", onClick: this.props.onShowImportClick }))] }));
    };
    ProductUpdateHeadline.defaultProps = {};
    return ProductUpdateHeadline;
}(React.Component));
export { ProductUpdateHeadline };
