var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "../utils";
export var useSetUrlSearchParam = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParam = _a[1];
    return function (params) {
        var o = cleanObject(__assign(__assign({}, Object.fromEntries(searchParams)), params));
        return setSearchParam(o);
    };
};
