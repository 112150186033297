var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuButton } from "../../modules/core/components";
import { Alert, Snackbar, Button, SpeedDial, SpeedDialIcon, SpeedDialAction, Drawer, } from "@mui/material";
import Keyboard from "@mui/icons-material/Keyboard";
import FileUpload from "@mui/icons-material/FileUpload";
import { Dropzone, FileItem, } from "@dropzone-ui/react";
import { useQueryClient } from "react-query";
import { useAuth, useUrlParams } from "@modules/core/hooks";
import { apiUrl } from "@modules/core/config";
export var ProductsNavbar = function () {
    var stateChildren = [
        { value: "", label: "ALL STATES" },
        { value: "ready", label: "READY" },
        { value: "review", label: "REVIEW" },
        { value: "request", label: "REQUEST" },
    ];
    var sortChildren = [
        { value: "latestUpdate", label: "LAST MODIFIED" },
        { value: "release", label: "RELEASEDATE" },
    ];
    var countryChildren = [
        { value: "", label: "ALL COUNTRIES" },
        { value: "de", label: "Germany" },
        { value: "at", label: "Austria" },
        { value: "ch", label: "Schweiz" },
    ];
    var user = useAuth().user;
    var navigate = useNavigate();
    var _a = useState([]), files = _a[0], setFiles = _a[1];
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(), uploadRes = _c[0], setUploadRes = _c[1];
    var _d = useState(false), openDial = _d[0], setOpenDial = _d[1];
    var _e = useState(false), openSnackbar = _e[0], setOpenSnackbar = _e[1];
    var queryClient = useQueryClient();
    var requestParams = useUrlParams();
    var updateFiles = function (incommingFiles) {
        setFiles(incommingFiles);
    };
    var onDelete = function (id) {
        if (files) {
            setFiles(files.filter(function (x) { return x.id !== id; }));
        }
    };
    var onUploadFinish = function (responses) {
        if (responses && responses.length > 0) {
            responses.map(function (file) {
                var fileResponse = file.serverResponse;
                setOpenSnackbar(true);
                setUploadRes({
                    status: fileResponse.status,
                    message: fileResponse.message,
                });
            });
        }
    };
    var closeDrawer = function () {
        if (files) {
            setFiles([]);
        }
        setOpen(false);
        queryClient.invalidateQueries("products");
    };
    var uploadConfigHeader = {
        params: {
            token: user === null || user === void 0 ? void 0 : user.token,
        },
    };
    var handleCloseSnackbar = function () {
        setOpenSnackbar(false);
    };
    return (_jsx("nav", __assign({ className: "games-navbar" }, { children: _jsxs("div", __assign({ className: "container" }, { children: [_jsxs("div", __assign({ className: "container-state" }, { children: [_jsx(MenuButton, { name: "state", defaultLabel: "ALL STATES", children: stateChildren }), _jsx(MenuButton, { name: "sort", defaultLabel: "LAST MODIFIED", children: sortChildren }), (user === null || user === void 0 ? void 0 : user.isAdmin) ? (_jsx(MenuButton, { name: "country", defaultLabel: "ALL COUNTRIES", children: countryChildren })) : ("")] })), _jsx("div", { children: _jsxs(SpeedDial, __assign({ ariaLabel: "SpeedDial basic example", sx: {
                            position: "absolute",
                            top: 46,
                            right: 15,
                            "& .MuiFab-primary": { width: 36, height: 36 },
                        }, direction: "left", open: openDial, onClose: function () { return setOpenDial(false); }, onClick: function () { return setOpenDial(!openDial); }, icon: _jsx(SpeedDialIcon, {}) }, { children: [_jsx(SpeedDialAction, { icon: _jsx(Keyboard, {}), tooltipTitle: "manual", onClick: function () { return navigate("/new" + requestParams); } }, "manual"), _jsx(SpeedDialAction, { icon: _jsx(FileUpload, {}), tooltipTitle: "upload", onClick: function () { return setOpen(true); } }, "upload")] })) }), _jsxs(Drawer, __assign({ anchor: "top", open: open, onClose: function () { return close(); } }, { children: [_jsx(Snackbar, __assign({ style: { top: "0" }, open: openSnackbar, autoHideDuration: 5000, anchorOrigin: { vertical: "top", horizontal: "center" }, onClose: handleCloseSnackbar }, { children: _jsx(Alert, __assign({ onClose: handleCloseSnackbar, severity: (uploadRes === null || uploadRes === void 0 ? void 0 : uploadRes.status) ? "success" : "error", sx: { width: "100%" } }, { children: uploadRes === null || uploadRes === void 0 ? void 0 : uploadRes.message })) })), _jsx(Dropzone, __assign({ url: apiUrl + "/preview", style: {
                                minWidth: "550px",
                                marginBottom: "1rem",
                                fontFamily: "roboto_condensedregular",
                            }, minHeight: "195px", config: uploadConfigHeader, onChange: updateFiles, onUploadFinish: onUploadFinish, footer: false, value: files, view: "list", accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel", maxFiles: 3, maxFileSize: 2998000, disableScroll: true }, { children: files.map(function (file) { return (_jsx(FileItem, __assign({}, file, { onDelete: onDelete, preview: true }), file.id)); }) })), _jsx(Button, __assign({ variant: "contained", onClick: function () { return closeDrawer(); } }, { children: "CLOSE" }))] }))] })) })));
};
