var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, } from "@mui/material";
export var DialogRemove = function (props) {
    var _a;
    var selectedid = props.selectedid, count = props.count, multi = props.multi, open = props.open, products = props.products, onClose = props.onClose;
    var handleOk = function () {
        onClose(false);
    };
    var handleCancel = function () {
        onClose(true);
    };
    return (_jsxs(Dialog, __assign({ className: "removeDialog", open: open, onClose: handleCancel }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: multi ? "REMOVE ITEMS" : "REMOVE ITEM" })), _jsxs(DialogContent, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: multi
                            ? "Are you sure you want to remove ALL items"
                            : "Are you sure you want to remove the selected item" })), _jsx(Typography, __assign({ variant: "subtitle2" }, { children: multi
                            ? count + " items are selected"
                            : (_a = products === null || products === void 0 ? void 0 : products.find(function (item) { return item.trid === selectedid; })) === null || _a === void 0 ? void 0 : _a.title }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: handleCancel, autoFocus: true }, { children: "CANCEL" })), _jsx(Button, __assign({ variant: "contained", onClick: handleOk }, { children: "OK" }))] })] })));
};
