var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { default as Button } from "@mui/material/IconButton";
import classnames from "classnames";
var styles = function (theme) { return ({
    margins: {
        margin: "0.3em",
    },
    small: {
        padding: 0,
    },
    iconSmall: {
        fontSize: 22,
    },
}); };
var IconButton = /** @class */ (function (_super) {
    __extends(IconButton, _super);
    function IconButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * render component
     */
    IconButton.prototype.render = function () {
        var _a;
        var _b = this.props, name = _b.name, classes = _b.classes, margins = _b.margins, small = _b.small, rest = __rest(_b, ["name", "classes", "margins", "small"]);
        var btnClassName = classnames((_a = {},
            _a[classes.margins] = margins,
            _a[this.props.classes.small] = small,
            _a));
        return (
        //<Button className={btnClassName} {...rest}><Icon color={this.props.disabled ? 'disabled' : this.props.iconColor}>{name}</Icon></Button>
        _jsx(Button, __assign({ className: btnClassName }, rest, { children: name })));
    };
    IconButton.defaultProps = {};
    return IconButton;
}(React.PureComponent));
export { IconButton };
