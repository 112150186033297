var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, CircularProgress, Button, Alert, TextField, IconButton, Typography, InputAdornment, InputLabel, FormControl, FormHelperText, Snackbar, } from "@mui/material";
import classnames from "classnames";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth, useAsync } from "@modules/core/hooks";
export var LoginPage = function (_a) {
    var _b, _c;
    var setIsLogin = _a.setIsLogin, onError = _a.onError;
    var initialForm = {
        username: "",
        password: "",
    };
    var _d = useState(false), playing = _d[0], setPlaying = _d[1];
    var _e = useState(false), showPassword = _e[0], setShowPassword = _e[1];
    var _f = useAuth(), login = _f.login, user = _f.user;
    var _g = useForm({
        mode: "onChange",
        defaultValues: initialForm,
    }), register = _g.register, handleSubmit = _g.handleSubmit, _h = _g.formState, errors = _h.errors, isValid = _h.isValid;
    var _j = useAsync(undefined, {
        throwOnError: true,
    }), run = _j.run, error = _j.error, isLoading = _j.isLoading, isError = _j.isError;
    var playClass = classnames("music-player-container", {
        "is-playing": playing,
    });
    var doSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, run(login({
                            username: data.username,
                            password: data.password,
                        }))];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    onError(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "signin" }, { children: [_jsx(Snackbar, __assign({ open: isError, autoHideDuration: 600, anchorOrigin: { vertical: "top", horizontal: "center" } }, { children: _jsx(Alert, __assign({ severity: "error", sx: { width: "100%" } }, { children: error === null || error === void 0 ? void 0 : error.message })) })), _jsxs("div", __assign({ className: playClass }, { children: [_jsx("div", __assign({ className: "music-player" }, { children: _jsxs("div", __assign({ className: "player-content-container" }, { children: [_jsx("img", { style: { top: "-40px", position: "relative" }, src: "./client/img/gfk_login.png", width: "105px" }), _jsx(Typography, { children: "Musik" }), _jsx(Typography, { children: "Meldeplattform" })] })) })), _jsxs("div", __assign({ className: "album" }, { children: [_jsx("div", __assign({ className: "album-art" }, { children: _jsxs("div", __assign({ id: "case1", className: "cd-case" }, { children: [_jsxs("div", __assign({ className: "cd-art" }, { children: [_jsx("div", { className: "sup pos-tl" }), _jsx("div", { className: "sup pos-tr" }), _jsx("div", { className: "sup pos-bl" }), _jsx("div", { className: "sup pos-br" }), _jsxs("form", __assign({ className: "signin-form", onFocus: function () { return setPlaying(true); }, onBlur: function () { return setPlaying(false); }, onSubmit: handleSubmit(function (data) {
                                                        doSubmit(data);
                                                    }) }, { children: [_jsx(TextField, __assign({}, register("username", {
                                                            required: "This field is required.",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "invalid email address",
                                                            },
                                                        }), { required: true, fullWidth: true, placeholder: "E-MAIL", variant: "standard", style: { textTransform: "none" }, label: "USERNAME", helperText: (_b = errors.username) === null || _b === void 0 ? void 0 : _b.message })), _jsxs(FormControl, __assign({ fullWidth: true, variant: "standard" }, { children: [_jsx(InputLabel, __assign({ htmlFor: "standard-adornment-password" }, { children: "Password" })), _jsx(Input, __assign({}, register("password", {
                                                                    required: "This field is required.",
                                                                    minLength: {
                                                                        value: 8,
                                                                        message: "Minimum 8 letters required.",
                                                                    },
                                                                }), { style: { textTransform: "none" }, type: showPassword ? "text" : "password", required: true, fullWidth: true, placeholder: "PASSWORD", 
                                                                    // label={"PASSWORD"}
                                                                    endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ "aria-label": "toggle password visibility", onClick: function () {
                                                                                setShowPassword(!showPassword);
                                                                            }, edge: "end" }, { children: showPassword ? _jsx(VisibilityOff, {}) : _jsx(Visibility, {}) })) })) })), _jsx(FormHelperText, { children: (_c = errors.password) === null || _c === void 0 ? void 0 : _c.message })] })), _jsxs("div", __assign({ className: "text-right" }, { children: [isLoading && _jsx(CircularProgress, { size: 30 }), !isLoading && (_jsx(Button, __assign({ disabled: !isValid, type: "submit", variant: "contained", color: "primary" }, { children: "Sign-In" })))] })), _jsx("div", __assign({ className: "text-right" }, { children: _jsx(Button, __assign({ className: "btnPasswordForgotten", variant: "text", onClick: function () { return setIsLogin(false); } }, { children: "Password forgotten" })) }))] }))] })), _jsx("div", { className: "spine" })] })) })), _jsx("div", { className: "vinyl" })] }))] }))] })));
};
