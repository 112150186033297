var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, Menu, MenuItem, Toolbar, Typography, IconButton, ListItemText, ListItemIcon, } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu, } from "material-ui-popup-state/hooks";
import { useAuth } from "@modules/core/hooks";
export var PageNavbar = function () {
    var _a = useAuth(), user = _a.user, logout = _a.logout;
    var navigate = useNavigate();
    var popupState = usePopupState({ variant: "popover", popupId: "setting" });
    var selectItem = function (menu) {
        navigate("/profile");
        popupState.close();
    };
    return (_jsx("div", __assign({ className: "page-nav no-border" }, { children: _jsx("div", __assign({ className: "container" + (user ? "" : " text-right") }, { children: user ? (_jsxs(Toolbar, __assign({ style: {
                    background: "white",
                    height: 39,
                    minHeight: 39,
                } }, { children: [_jsx(IconButton, __assign({ size: "large", edge: "start", color: "inherit", "aria-label": "menu", sx: { mr: 2 } }, { children: _jsx(MenuIcon, {}) })), _jsx(Typography, __assign({ variant: "h6", component: "div", sx: { flexGrow: 1 } }, { children: "MUSIC MELDEPLATTFORM" })), _jsx(Button, __assign({ color: "inherit", variant: "text", startIcon: _jsx(PersonIcon, {}), disableElevation: true }, bindTrigger(popupState), { children: "hallo " + user.username })), _jsxs(Menu, __assign({}, bindMenu(popupState), { anchorOrigin: { vertical: "bottom", horizontal: "left" }, transformOrigin: { vertical: "top", horizontal: "left" } }, { children: [_jsxs(MenuItem, __assign({ onClick: function () { return selectItem("profile"); } }, { children: [_jsx(ListItemIcon, { children: _jsx(SettingsIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "PROFILE" })] })), _jsxs(MenuItem, __assign({ onClick: function () { return logout(); } }, { children: [_jsx(ListItemIcon, { children: _jsx(ExitToAppIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "LOGOUT" })] }))] }))] }))) : (_jsx(Button, __assign({ variant: "text", href: "/", color: "inherit" }, { children: "login" }))) })) })));
};
