var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as auth from "./auth-provider";
import { AuthContext } from "../interfaces";
import { LoadingIndicator } from "../components";
import { http } from "../utils/http";
import { useMount, useAsync } from "../hooks";
import { apiUrl } from "@modules/core/config";
var bootstrapUser = function () { return __awaiter(void 0, void 0, void 0, function () {
    var user, token, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                user = null;
                token = auth.getToken();
                if (!token) return [3 /*break*/, 2];
                return [4 /*yield*/, http("ck", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { userToken: token },
                        url: apiUrl,
                    })];
            case 1:
                data = _a.sent();
                if (data.status) {
                    return [2 /*return*/, {
                            email: data.email,
                            id: data.id,
                            isAdmin: data.isAdmin,
                            username: data.name,
                            token: data.userToken,
                        }];
                }
                _a.label = 2;
            case 2: return [2 /*return*/, user];
        }
    });
}); };
AuthContext.displayName = "AuthContext";
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useAsync(), user = _b.data, error = _b.error, isLoading = _b.isLoading, isIdle = _b.isIdle, isError = _b.isError, run = _b.run, setUser = _b.setData;
    var login = function (form) {
        return auth.login(form).then(function (user) {
            setUser(user);
        });
    };
    var passwordForgot = function (form) {
        return auth.passwordForgot(form).then(function (res) {
            return res;
        });
    };
    var passwordResetByToken = function (form) {
        return auth.passwordResetByToken(form).then(function (res) {
            return res;
        });
    };
    var changePassword = function (form) {
        return auth.changePassword(form).then(function (res) {
            return res;
        });
    };
    var register = function (form) {
        return auth.register(form).then(function (user) { return setUser(user); });
    };
    var logout = function () { return auth.logout().then(function () { return setUser(null); }); };
    useMount(function () {
        run(bootstrapUser());
    });
    if (isIdle || isLoading) {
        return _jsx(LoadingIndicator, { pageCentered: true, text: "Loading" });
    }
    return (_jsx(AuthContext.Provider, { children: children, value: {
            user: user,
            login: login,
            register: register,
            passwordForgot: passwordForgot,
            passwordResetByToken: passwordResetByToken,
            changePassword: changePassword,
            logout: logout,
        } }));
};
